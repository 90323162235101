<template>
	<BlockEcommerceProduct
		v-bind="{
			blockId,
			blockStyle,
			blockButtonText,
			blockButtonStyle,
			blockButtonType,
			blockButtonBorderWidth,
			textColorVars,
			imageBorderRadius,
			navigationArrowsColor,
			navigationThumbnailArrowsColor,
			galleryPlacement,
			imageRatio,
			isQuantityPickerEnabled,
			quantifiedCartItemsList,
			isCheckoutLoading,
			productPages,
			isQuickPreview,
			currentPageType
		}"
		:product-data="product"
		:can-add-to-cart="canAddToCart"
		:is-loading="(isLoading || !isLoaded) && productId !== -1"
		:translations="ecommerceTranslations"
		:is-cart-visible="isCartVisible"
		:is-eager="lcp.type === 'block-ecommerce-product' && lcp.id === blockId"
		:[DATA_ATTRIBUTE_ANIMATION_STATE]="animationState"
		:site-id="siteId"
		:shopping-cart-items="shoppingCartItems"
		:variants-quantity="variantsQuantity"
		@buy-button-click="handleBuyButtonClick"
		@click-more="closeEcommerceModal"
		@image-click="handleImageClick"
	/>
</template>

<script setup lang="ts">
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants';
import BlockEcommerceProduct from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProduct.vue';
import { useBlockEcommerceProduct } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProduct';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';

import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import {
	DEFAULT_EMPTY_PRODUCT_VALUE,
	PRODUCT_TYPE_BOOKING,
} from '@zyro-inc/site-modules/constants/ecommerce';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import {
	EcommerceProduct,
	EcommerceProductPage,
	SiteBlock,
} from '@zyro-inc/site-modules/types/index';

import { addProductJsonLd } from '@zyro-inc/site-modules/utils/addProductJsonLd';

import {
	computed,
	onMounted,
	onBeforeUnmount,
	ref,
	watch,
} from 'vue';

type Props = {
	blockId: string;
	data: SiteBlock;
	lcp: {
		type?: string;
		id?: string;
	};
	ecommerceTranslations: Record<string, string>;
	isQuickPreview: boolean;
	productPages: Array<EcommerceProductPage>;
	isCartVisible: boolean;
	currentPageType: string;
	isInPreviewMode: boolean;
};

const props = withDefaults(defineProps<Props>(), {
	blockId: '',
	lcp: () => ({}),
	ecommerceTranslations: () => ({}),
	isQuickPreview: false,
	productPages: () => [],
	isCartVisible: false,
	currentPageType: 'default',
	isInPreviewMode: false,
});

const { siteId } = useSiteGlobal();
const {
	quantifiedCartItemsList,
	canAddToCart,
	isShoppingCartOpen,
	products,
	shoppingCartItems,
	variantsQuantity,
	isCheckoutLoading,
	isLoading,
	isLoaded,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
	setSelectedBookingId,
} = useEcommerceGlobal();
const {
	openEcommerceModal,
	closeEcommerceModal,
} = useEcommerceModal();
const { initiateCheckout } = useEcommerce(props);
const { addImagesToLightbox } = useLightbox();
const {
	productId,
	blockStyle,
	blockButtonText,
	blockButtonStyle,
	blockButtonType,
	blockButtonBorderWidth,
	textColorVars,
	imageBorderRadius,
	navigationArrowsColor,
	navigationThumbnailArrowsColor,
	galleryPlacement,
	imageRatio,
	isQuantityPickerEnabled,
} = useBlockEcommerceProduct(props);

const product = computed<EcommerceProduct>(() => products.value
	.find((item: EcommerceProduct) => item.id === productId.value) || DEFAULT_EMPTY_PRODUCT_VALUE);

const productPage = computed(() => props.productPages.find((page) => page.productId === product.value.id));

const animationState = ref<string | null>(null);

const setAnimationState = () => {
	setTimeout(() => {
		animationState.value = DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE;
	}, 100);
};

const manageCartOpenState = () => {
	if (isShoppingCartOpen.value) {
		return;
	}

	setShoppingCartOpen(true);
};

const handleImageClick = (index) => {
	const aggregatedImages = product.value.images.map((image) => ({
		alt: product.value.title,
		src: image.url,
	}));

	addImagesToLightbox(aggregatedImages, index);
};

const handleBuyButtonClick = async (value) => {
	// Need to use await here so that all previous modals would get closed for others to open up
	await closeEcommerceModal();

	if (product.value.type.value === PRODUCT_TYPE_BOOKING) {
		setSelectedBookingId(productId.value);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (getIsInIframe() || props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			...value,
		]);

		manageCartOpenState();
	} else {
		setIsCheckoutLoading(true);

		await initiateCheckout(value);

		setIsCheckoutLoading(false);
	}
};

onMounted(() => {
	setAnimationState();
});

onBeforeUnmount(() => {
	closeEcommerceModal();
});

watch(product, () => {
	if (product.value && productPage.value) {
		addProductJsonLd({
			product: product.value,
			pageSlug: productPage.value?.slug,
		});
	}
});

</script>
